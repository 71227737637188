import C from "../constants";
const allMarkets = (state = [], action) => {
  switch (action.type) {
    case C.SET_ALL_MARKETS:
      return action.payload;
    default:
      return state;
  }
};
export default allMarkets;
