import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { ThemeProvider } from "@material-ui/styles";

import mainTheme from "assets/jss/Theme";

export default function SplashScreen(props) {
  const [logo] = React.useState(require("assets/img/logo.png"));
  const [logo_small] = React.useState(require("assets/img/logo_small.png"));
  const [loadingMessage] = useState("Loading...");

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ThemeProvider theme={mainTheme}>
      <div
        style={{
          width: "100%",
          height: "calc(100vh)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          /*  backgroundImage: matchesSM
            ? "inherit"
            : "linear-gradient(#92267e, #030d57)", */
          backgroundImage: "linear-gradient(#92267e, #030d57)",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <img
            src={matchesXS || matchesSM ? logo_small : logo}
            alt="logo"
            style={{ width: matchesXS || matchesSM ? "250px" : "400px" }}
          />
          <Typography
            style={{ marginTop: "50px", fontSize: "20px", color: "white" }}
          >
            {loadingMessage}
          </Typography>
        </div>
      </div>
    </ThemeProvider>
  );
}
