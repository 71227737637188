import C from "../constants";
export const setAppLoading = (loading) => (dispatch) => {
  dispatch({
    type: C.SET_APP_LOADING,
    payload: loading,
  });
};

export const setUser = (user) => (dispatch) => {
  dispatch({
    type: C.SET_USER,
    payload: user,
  });
};

export const setDisclaimer = (disclaimer) => (dispatch) => {
  dispatch({
    type: C.SET_DISCLAIMER,
    payload: disclaimer,
  });
};

export const setAdminLoading = (loading) => (dispatch) => {
  dispatch({
    type: C.SET_ADMIN_LOADING,
    payload: loading,
  });
};

export const setSubscription = (subscription) => (dispatch) => {
  dispatch({
    type: C.SET_SUBSCRIPTION,
    payload: subscription,
  });
};

export const setAllMarkets = (marketData) => (dispatch) => {
  dispatch({
    type: C.SET_ALL_MARKETS,
    payload: marketData,
  });
};

export const setSelectedMarkets = (selectedMarketData) => (dispatch) => {
  dispatch({
    type: C.SET_SELECTED_MARKETS,
    payload: selectedMarketData,
  });
};

export const setActiveMarket = (activeMarket) => (dispatch) => {
  dispatch({
    type: C.SET_ACTIVE_MARKET,
    payload: activeMarket,
  });
};

export const setTutorials = (tutorials) => (dispatch) => {
  dispatch({
    type: C.SET_TUTORIALS,
    payload: tutorials,
  });
};

export const setRules = (rules) => (dispatch) => {
  dispatch({
    type: C.SET_RULES,
    payload: rules,
  });
};

export const setAlerts = (alerts) => (dispatch) => {
  dispatch({
    type: C.SET_ALERTS,
    payload: alerts,
  });
};
