const defaultTutorials = [
  {
    id: "0",
    order: 0,
    title: "How to Navigate FX Price Zone",
    details: "Learn how to navigate the tool",
    videoUrl: "https://vimeo.com/552122613",
  },
  {
    id: "1",
    order: 1,
    title: "Turning on Alerts",
    details: "Learn how to turn on your FX Price Zone Alerts.",
    videoUrl: "https://vimeo.com/552196842",
  },
  {
    id: "2",
    order: 2,
    title: "FX Price Zone & Market Structure",
    details: "Learn how to follow the trend of the market",
    videoUrl: "https://vimeo.com/552126234",
  },
  {
    id: "3",
    order: 3,
    title: "Recent Outlook",
    details: "Weekly Outlook 5/12/21",
    videoUrl: "https://vimeo.com/547308955",
  },
];

module.exports = {
  defaultTutorials,
};
