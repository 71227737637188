import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";
//// processor
//import { cloneObject } from "processor/dataProcessor";
export const BASE_URL =
  // "https://oau3pmuebl.execute-api.us-east-1.amazonaws.com/dev"; ////// development /// stripe in testing
  "https://dzfuny0gl6.execute-api.us-east-1.amazonaws.com/prod"; ////// production //// stripe is live
// "http://localhost:4000";
//"http://192.168.1.14:4000";

export const interceptorProvider = (store) => {
  axios.interceptors.request.use(async (config) => {
    if (config.url.indexOf(BASE_URL) !== -1) {
      if (firebase.auth().currentUser) {
        let idToken = await firebase.auth().currentUser.getIdToken();
        config.headers.Authorization = `Bearer ${idToken}`;
        const clients = store.getState().clients;
        const userConfig = store.getState().userConfig;
        let workspaceProps;
        if (userConfig.workspace) {
          workspaceProps = userConfig.workspace;
        } else {
          workspaceProps = {
            workspaceId: "own",
            workspaceEmail: "",
            clientSubscriptionType: "expanded",
          };
        }
        const activeClient = clients.find(
          (client) => client.client_id === workspaceProps.workspaceId
        );
        if (
          workspaceProps.workspaceId !== "own" &&
          activeClient &&
          !isUserLink(config.url)
        ) {
          config.params = {
            ...config.params,
            client_email: activeClient.client.email,
            //delegateEmail: "contact2aamer@gmail.com",
          };
        }
      }
    }
    return config;
  });
};

const isUserLink = (url) => {
  const userLinks = [
    "/google_login",
    "/prices",
    "/subscription",
    "/coupon",
    "/billing",
    "/otp",
    "/email",
    "/collaborate",
    "/clients",
    "/client_request",
    "/user_config",
    "/create-customer-portal-session",
    "/notes",
  ];
  let index = userLinks.find((userLink) => url.search(userLink) !== -1);
  if (index) {
    return true;
  } else {
    return false;
  }
};

export const loginUserWithIdToken = async (idToken) => {
  return axios.post(
    BASE_URL + "/google_login",
    {},
    {
      headers: { Authorization: `Bearer ${idToken}` },
    }
  );
  /* .then((response) => {
      dispatch(setUser(response.data.user));
    })
    .catch((error) => dispatch(addError(error))); */

  /// new changes
};

export const updateSetupOnServer = async (setup) => {
  let idToken = await firebase.auth().currentUser.getIdToken();
  return axios.put(
    BASE_URL + `/setup/${setup.id}`,
    {
      setup: setup,
    },
    {
      headers: { Authorization: `Bearer ${idToken}` },
    }
  );
};

export const updateFiltersOnServer = (setup) => {
  //let setup = cloneObject(_setup);
  //setup.filters.searchValue = "";
  axios.put(BASE_URL + `/setup/${setup.id}`, {
    setup: setup,
  });
};

export const createNewFileOnServer = async (metadata) => {
  let idToken = await firebase.auth().currentUser.getIdToken();
  return axios.post(
    BASE_URL + "/files",
    {
      metadata: metadata,
    },
    {
      headers: { Authorization: `Bearer ${idToken}` },
    }
  );
};

export const getFileList = async () => {
  let idToken = await firebase.auth().currentUser.getIdToken();

  return axios.get(BASE_URL + "/files", {
    headers: { Authorization: `Bearer ${idToken}` },
  });
};

export const getFileRecords = async (file_id) => {
  let idToken = await firebase.auth().currentUser.getIdToken();

  return axios.get(BASE_URL + `/file/${file_id}`, {
    headers: { Authorization: `Bearer ${idToken}` },
  });
};

export const updateFileMetaData = async (metadata) => {
  let idToken = await firebase.auth().currentUser.getIdToken();
  return axios.put(
    BASE_URL + "/files",
    {
      metadata: metadata,
    },
    {
      headers: { Authorization: `Bearer ${idToken}` },
    }
  );
};

export const deleteFileFromServer = async (id) => {
  let idToken = await firebase.auth().currentUser.getIdToken();
  return axios.delete(BASE_URL + `/file/${id}`, {
    headers: { Authorization: `Bearer ${idToken}` },
  });
};

export const addRecordsOnServer = async (records, file_id) => {
  let idToken = await firebase.auth().currentUser.getIdToken();
  return axios.post(
    BASE_URL + "/records",
    {
      records: records,
      file_id: file_id,
    },
    {
      headers: { Authorization: `Bearer ${idToken}` },
    }
  );
};

export const updateRecordsOnServer = async (records) => {
  let idToken = await firebase.auth().currentUser.getIdToken();
  return axios.put(
    BASE_URL + `/records`,
    {
      records: records,
    },
    {
      headers: { Authorization: `Bearer ${idToken}` },
    }
  );
};
export const deleteRecordsOnServer = async (records) => {
  let idToken = await firebase.auth().currentUser.getIdToken();
  return axios.delete(BASE_URL + `/records`, {
    headers: { Authorization: `Bearer ${idToken}` },
    data: {
      records: records.map((record) => {
        return { id: record.id, attachments: record.attachments };
      }),
    },
  });
};

export const getUserConfig = () => {
  return axios.get(BASE_URL + "/user_config");
};

export const getAppConfig = () => {
  return axios.get(BASE_URL + "/app_config");
};

export const updateUserConfigOnServer = (configObj) => {
  return axios.put(BASE_URL + "/user_config", {
    config: configObj,
  });
};

/////////////////////////////
///// NOTES API ////////////
////////////////////////////

export const getNotes = () => {
  return axios.get(BASE_URL + "/notes");
};

export const addNoteOnServer = (note) => {
  return axios.post(BASE_URL + "/notes", { note: note });
};

export const updateNoteOnServer = (note) => {
  return axios.put(BASE_URL + "/notes", { note: note });
};

export const updateNoteIdOnServer = (noteId) => {
  return axios.put(BASE_URL + "/active_note_id", { id: noteId });
};

export const deleteNoteOnServer = (noteId) => {
  return axios.delete(BASE_URL + "/notes", { data: { id: noteId } });
};

/////////////// ATTACHMENTS

export const getUploadSignedUrl = (file, file_id) => {
  return axios.post(BASE_URL + "/imageUrl", {
    type: file.type,
    name: file.name,
    file_id: file_id,
  });
  /* .then((response) => response.data)
    .catch((error) => console.log(error)); */
};

export const uploadImage = (url, file) => {
  var options = {
    headers: {
      "Content-Type": file.type,
    },
  };

  return axios.put(url, file, options);
};

export const getDownloadUrl = (keys) => {
  return axios.get(BASE_URL + "/imageUrl", {
    params: {
      keys: JSON.stringify(keys),
    },
  });
};

export const deleteImage = (keys) => {
  return axios.delete(BASE_URL + "/imageUrl", {
    data: {
      keys: keys,
    },
  });
};

export const createImportedFile = (fileMetaData, setup, records) => {
  return axios.post(BASE_URL + "/imp_file", {
    metaData: fileMetaData,
    setup: setup,
    records: records,
  });
};

export const getPrices = () => {
  return axios.get(BASE_URL + "/prices");
};

export const activateTrial = (priceId, promoCodeId) => {
  return axios.post(BASE_URL + "/subscription", {
    priceId: priceId,
    promotion_code: promoCodeId ? promoCodeId : undefined,
  });
};

export const addCouponToSubscription = (promotion_code) => {
  return axios.post(BASE_URL + "/coupon", {
    promotion_code: promotion_code,
  });
  //.then((response) => console.log(response.data));
};

export const getBilling = () => {
  return axios.get(BASE_URL + "/billing");
};

export const generateOTP = (newEmail) => {
  return axios.post(BASE_URL + "/otp", {
    newEmail: newEmail,
  });
};

export const changeEmail = (newEmailAddress, otp, new_account_otp) => {
  return axios.post(BASE_URL + "/email", {
    newEmailAddress: newEmailAddress,
    otp: otp,
    new_account_otp: new_account_otp,
  });
};

export const addTempRecordsOnServer = (records, file_id) => {
  return axios.post(BASE_URL + "/temp_records", {
    records: records,
    file_id: file_id,
  });
};

export const getFileTempRecord = (file_id) => {
  return axios.get(BASE_URL + "/temp_records", {
    params: {
      file_id: file_id,
    },
  });
};

//////// Collaborators

export const addCollaboratorOnServer = (col_email) => {
  return axios.post(BASE_URL + `/collaborate`, {
    collaborator_email: col_email,
  });
};

export const getCollaborator = () => {
  return axios.get(BASE_URL + "/collaborate");
};

export const updateCollaboratorFileOnServer = (id, files) => {
  //"user_collaborator_1625901580444"
  return axios.put(BASE_URL + "/collaborate/files", {
    id: id,
    files: files,
  });
};

export const deleteCollaboratorOnServer = (id) => {
  return axios.delete(BASE_URL + "/collaborate", { data: { id: id } });
};

export const getClients = () => {
  return axios.get(BASE_URL + "/clients");
};

export const handleClientRequest = (client_id, status) => {
  return axios.post(BASE_URL + "/client_request", {
    client_id: client_id,
    status: status, ///// active, rejected, pending (default)
  });
};

export const getLiveSymbols = async () => {
  let crypto = await axios
    .get(
      "https://fcsapi.com/api-v3/forex/list?access_key=IkiSAY3ZWp3X2USsID1v9cJJSW5VZS9ymre5IqXSCtZeNaytLV&type=crypto"
    )
    .then((response) =>
      response.data.response.map((res) => ({ ...res, type: "forex" }))
    );
  let forex = await axios
    .get(
      "https://fcsapi.com/api-v3/forex/list?access_key=IkiSAY3ZWp3X2USsID1v9cJJSW5VZS9ymre5IqXSCtZeNaytLV&type=forex"
    )
    .then((response) =>
      response.data.response.map((res) => ({ ...res, type: "forex" }))
    );
  let stock = await getStockSymbols().catch((error) =>
    console.log(JSON.stringify(error, 0, 2))
  );
  let indices = await getIndicesSymbols();
  return [...crypto, ...forex, ...stock, ...indices];
};

export const getStockSymbols = async () => {
  return await axios
    .get(
      `https://fcsapi.com/api-v3/stock/list?access_key=IkiSAY3ZWp3X2USsID1v9cJJSW5VZS9ymre5IqXSCtZeNaytLV&country=united-states`
    )
    .then((response) => {
      return response.data.response.map((res) => ({
        ...res,
        symbol: res.short_name,
        type: "stock",
      }));
    });
};

export const getIndicesSymbols = async () => {
  return await axios
    .get(
      `https://fcsapi.com/api-v3/stock/indices?country=United-states&access_key=IkiSAY3ZWp3X2USsID1v9cJJSW5VZS9ymre5IqXSCtZeNaytLV&country=united-states`
    )
    .then((response) => {
      return response.data.response.map((res) => ({
        ...res,
        symbol: res.index_name,
        type: "index",
      }));
    });
};
