import appLoading from "./appLoading";
import user from "./user";
import adminLoading from "./adminLoading";
import subscription from "./subscription";
import allMarkets from "./allMarkets";
import selectedMarkets from "./selectedMarkets";
import activeMarket from "./activeMarket";
import tutorials from "./tutorials";
import rules from "./rules";
import alerts from "./alerts";
import disclaimer from "./disclaimer";
import { combineReducers } from "redux";

const allReducers = combineReducers({
  appLoading,
  user,
  adminLoading,
  subscription,
  allMarkets,
  selectedMarkets,
  activeMarket,
  tutorials,
  rules,
  alerts,
  disclaimer,
});

export default allReducers;
