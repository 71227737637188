let C = {
  SET_APP_LOADING: "SET_APP_LOADING",
  SET_USER: "SET_USER",
  SET_SUBSCRIPTION: "SET_SUBSCRIPTION",
  SET_ADMIN_LOADING: "SET_ADMIN_LOADING",
  SET_ALL_MARKETS: "SET_ALL_MARKETS",
  SET_SELECTED_MARKETS: "SET_SELECTED_MARKETS",
  SET_ACTIVE_MARKET: "SET_ACTIVE_MARKET",
  SET_TUTORIALS: "SET_TUTORIALS",
  SET_RULES: "SET_RULES",
  SET_ALERTS: "SET_ALERTS",
  SET_DISCLAIMER: "SET_DISCLAIMER",
};

export default C;
