import C from "../constants";
import { defaultTutorials } from "../data/defaultTutorials";
const tutorials = (state = defaultTutorials, action) => {
  switch (action.type) {
    case C.SET_TUTORIALS:
      return action.payload;
    default:
      return state;
  }
};
export default tutorials;
