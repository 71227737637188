import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/analytics";

var firebaseConfig = {
  apiKey: "AIzaSyAJbiqXEdsgW_5rIvRt1nPmUODyD2cszYo",
  authDomain: "fx-price-zone.firebaseapp.com",
  projectId: "fx-price-zone",
  storageBucket: "fx-price-zone.appspot.com",
  messagingSenderId: "627774040453",
  appId: "1:627774040453:web:dd39500275dd82bbbbfcec",
  measurementId: "G-ZR7XPPC26F",
};
// firebase.analytics();
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
app.analytics();
export default app;
