import C from "../constants";
const defaultMarkets = [
  { decimal: "4", id: "1", name: "Euro US Dollar", symbol: "EUR/USD" },
];
const selectedMarkets = (state = defaultMarkets, action) => {
  switch (action.type) {
    case C.SET_SELECTED_MARKETS:
      return action.payload;
    default:
      return state;
  }
};
export default selectedMarkets;
