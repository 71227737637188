import C from "../constants";
import { defaultAlerts } from "../data/defaultAlerts";
const alerts = (state = defaultAlerts, action) => {
  switch (action.type) {
    case C.SET_ALERTS:
      return action.payload;
    default:
      return state;
  }
};
export default alerts;
