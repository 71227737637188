const checkIfBaseSubscription = (user) => {
  if (user.stripe.subscriptionItems.length > 0) {
    let mainSub = user.stripe.subscriptionItems[0].items.find(
      (item) =>
        item.price.metadata.name === "main" ||
        item.price.metadata.name === "basic"
    );
    if (mainSub && mainSub.price.metadata.status !== "canceled") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const checkIfActiveByKey = (user) => {
  let { activeByKey } = user;
  if (activeByKey) {
    return true;
  } else {
    return false;
  }
};

const checkIfImpetusSubscription = (user) => {
  if (user.stripe.subscriptionItems.length > 0) {
    let premiumSub = user.stripe.subscriptionItems[0].items.find(
      (item) => item.price.metadata.level === "impetus"
    );
    if (premiumSub && premiumSub.price.metadata.status !== "canceled") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const checkIfGCISubscription = (user) => {
  if (user.stripe.subscriptionItems.length > 0) {
    let premiumSub = user.stripe.subscriptionItems[0].items.find(
      (item) => item.price.metadata.level === "gci"
    );
    if (premiumSub && premiumSub.price.metadata.status !== "canceled") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const getPremiumSubscriptionItemId = (user, priceItem) => {
  if (user.stripe.subscriptionItems.length > 0) {
    let premiumSub = user.stripe.subscriptionItems[0].items.find(
      (item) => item.price.id === priceItem.id
    );
    console.log("premiumSub is ", premiumSub);
    if (premiumSub) {
      return premiumSub.id;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const ifSubscribeToPrice = (user, priceItem) => {
  console.log(user);
  if (user.stripe.subscriptionItems.length > 0) {
    let sub = user.stripe.subscriptionItems[0].items.find(
      (item) => item.price.id === priceItem.id
    );
    if (sub) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

const getBaseSubscriptionStatus = (user) => {
  if (user.stripe.subscriptionItems.length > 0) {
    let mainSub = user.stripe.subscriptionItems[0].items.find(
      (item) => item.price.metadata.name === "main"
    );
    if (mainSub) {
      return mainSub.price.metadata.status;
    } else {
      return "canceled";
    }
  } else {
    return "canceled";
  }
};

const getSubscriptionId = (user) => {
  if (user.stripe.subscriptionItems.length > 0) {
    return user.stripe.subscriptionItems[0].id;
  } else {
    return null;
  }
};

const getItemSubscriptionId = (user, priceItem) => {
  if (user.stripe.subscriptionItems.length > 0) {
    let premiumSub = user.stripe.subscriptionItems[0].items.find(
      (item) => item.id === priceItem.id
    );
    if (premiumSub) {
      return premiumSub.id;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default {
  checkIfActiveByKey,
  checkIfBaseSubscription,
  getBaseSubscriptionStatus,
  checkIfImpetusSubscription,
  checkIfGCISubscription,
  getPremiumSubscriptionItemId,
  getSubscriptionId,
  ifSubscribeToPrice,
  getItemSubscriptionId,
};
