import C from "../constants";
const disclaimer = (state = false, action) => {
  switch (action.type) {
    case C.SET_DISCLAIMER:
      return action.payload;
    default:
      return state;
  }
};
export default disclaimer;
