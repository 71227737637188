const defaultAlerts = [
  {
    id: "0",
    market: "EUR/JPY",
    type: "Between",
    prices: [127.47, 127.26],
    triggered: false,
  },
  {
    id: "1",
    market: "EUR/USD",
    type: "Above",
    prices: [127.47, 0],
    triggered: true,
  },
  {
    id: "2",
    market: "BTC/USD",
    type: "Below",
    prices: [127.47, 0],
    triggered: false,
  },
];

module.exports = {
  defaultAlerts,
};
