import C from "../constants";
const activeMarket = (state = [], action) => {
  switch (action.type) {
    case C.SET_ACTIVE_MARKET:
      return action.payload;
    default:
      return state;
  }
};
export default activeMarket;
