const defaultRules = [
  {
    id: "0",
    order: 0,
    title: "Money Management Template",
    rules: [
      { id: "0", content: "Risk .01 per $100 or .1 per $1000" },
      {
        id: "1",
        content: "Don’t risk more than 3% of your account at one time",
      },
      {
        id: "2",
        content: "Limit your total number of open trades",
      },
    ],
  },
  {
    id: "1",
    order: 1,
    title: "Buy Limit/Sell Limit Strategy",
    rules: [
      { id: "0", content: "Twin Trade" },
      {
        id: "1",
        content: "Set Pending order",
      },
      {
        id: "2",
        content: "Set order 1 take profit at 15 pips from entry",
      },
      { id: "3", content: "Set order 2 take profit at next price zone" },
      {
        id: "4",
        content:
          "Once the market has moved 15 pips in your favor, move stop loss on trailing order to a risk free trade.",
      },
      {
        id: "5",
        content: "Stop Losses should be set at no more than 3% of your account",
      },
    ],
  },
  {
    id: "2",
    order: 2,
    title: "Trading the Breakout Strategy",
    rules: [
      {
        id: "0",
        content:
          "Wait for price to break and close above resistance or break and close below support with a strong volume indication",
      },
      {
        id: "1",
        content:
          "Wait for price to pullback to the entry zone and get confirmation to buy or sell or set a pending order at the entry zone",
      },
      {
        id: "2",
        content: "Twin Trade",
      },
      {
        id: "3",
        content: "Set order 1 take profit at 15 pips from entry",
      },
      {
        id: "4",
        content: "Set order 2 take profit at next price zone",
      },
      {
        id: "5",
        content:
          "Once the market has moved 15 pips in your favor, move stop loss on trailing order to a risk free trade.",
      },
      {
        id: "6",
        content: "Stop Losses should be set at no more than 3% of your account",
      },
    ],
  },
];

module.exports = {
  defaultRules,
};
