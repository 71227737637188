import { createMuiTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

const primaryColors = ["#0466c8", "#0353a4", "#023e7d"];
const secondaryColors = ["#E83594", "#E7238B", "#DC1881"];
const roseColor = ["#e91e63", "#ec407a", "#d81b60", "#eb3573", "#c2185b"];

export default createMuiTheme({
  palette: {
    common: {
      light: primaryColors[0],
      main: primaryColors[1],
      dark: primaryColors[2],
    },
    primary: {
      light: primaryColors[0],
      main: primaryColors[1],
      dark: primaryColors[2],
    },
    secondary: {
      light: secondaryColors[0],
      main: secondaryColors[1],
      dark: secondaryColors[2],
    },
    warning: {
      light: roseColor[0],
      main: roseColor[1],
      dark: roseColor[2],
    },
  },
  typography: {
    root: {
      pointerEvents: "none",
    },
    caption: {
      color: grey[600],
    },
    h4: {
      color: primaryColors[0],
    },
  },
  overrides: {
    /* MuiInputBase: {
      input: {
        background: "#fff",
      },
    }, */

    MuiTooltip: {
      tooltip: {
        fontSize: ".8em",
        fontWeight: "normal",
        color: "white",
        backgroundColor: primaryColors[0],
      },
      arrow: {
        color: primaryColors[0],
      },
    },
    MuiTypography: {
      root: {
        userSelect: "text",
        //pointerEvents: "none",
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: "4px",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        //borderRight: "1px solid rgba(224, 224, 224, 1)",
      },
      sizeSmall: {
        padding: "6px 6px 6px 6px",
      },
    },
    MuiDialog: {
      paperWidthMd: {
        width: "800px",
        height: "680px",
      },
    },

    /*  MuiTablePagination: {
      selectIcon: {
        position: "relative",
        color: "red",
        width: "30px",
        height: "30px",
      },
      actions: {
        //color: "red",
        "& .MuiButtonBase-root:not([disabled])": {
          color: "blue",
          width: "20px",
          height: "20px",
        },
        "& .MuiButtonBase-root": {
          color: "yellow",
          fontsize: ".5rem",
        },
      },
    }, */
    MuiTableRow: {
      /* hover: {
        "&:hover": {
          backgroundColor: "rgba(33, 150, 243, 0.25) !important",
        },
      },
      root: {
        "&$selected": {
          backgroundColor: lighten(primaryColor[0], 0.8),
        },
      }, */
    },
    MuiButton: {
      root: {
        "&:focus": {
          //color: "rgba(0, 0, 0, 0.87)",
          //backgroundColor: secondaryColors[1],
        },
      },
      startIcon: {
        marginTop: "-4px",
      },
    },
  },
  props: {
    MuiButton: {
      //disableRipple: true,
    },
  },
});
