import C from "../constants";
import { defaultRules } from "../data/defaultRules";
const rules = (state = defaultRules, action) => {
  switch (action.type) {
    case C.SET_RULES:
      return action.payload;
    default:
      return state;
  }
};
export default rules;
